<template>
  <vl-map
    id="map"
    ref="map"
    :data-projection="projection"
    :renderer="view.renderer"
    class="d-map"
    load-tiles-while-animating
    load-tiles-while-interacting
    style="position: relative;"
    @click="click"
    @created="mapCreated"
  >
    <import-features-dialog ref="importFeaturesDialog"/>

    <slot/>

    <map-search-slide-bar
      v-if="$refs.view && !widget && !displayXS && isAuthenticated"
      ref="mapSearch"
      :extent="view.extent"
      :geo-search.sync="geoSearch"
      :layers="visibleLayers"
      :view="$refs.view"
      @show="showPoi"
      @showBbox="showBbox"
      @showPkkFeature="showPkkFeature"
    />

    <map-search
      v-else-if="$refs.view && !widget"
      ref="mapSearch"
      :extent="view.extent"
      :geo-search.sync="geoSearch"
      :layers="visibleLayers"
      :view="$refs.view"
      @show="showPoi"
      @showBbox="showBbox"
      @showPkkFeature="showPkkFeature"
    />

    <d-map-controls
      v-model="view"
      :hide-top="selected.length === 1"
      :showNorthButton="showNorthButton"
      :state="state"
      :widget="widget"
      @create="createFeature"
      @export="makeExport"
      @findUserLocation="findUserLocation"
      @openLayersDialog="$emit('openLayersDialog')"
      @openSearchLpDialog="$emit('openSearchLpDialog')"
      @restore-rotation="restoreRotation"
    />

    <feature-selector
      ref="featureSelector"
      @show="ev => showFeature(ev.feature, false, ev.multiselect)"
    />

    <map-context-menu
      v-if="map"
      ref="contextMenu"
      :map-id="map.id"
      @createFeature="createFeature"
      @createMapIssue="createMapIssue"
    />

    <vl-view
      ref="view"
      :center.sync="view.center"
      :extent="view.extent"
      :resolution.sync="resolution"
      :rotation.sync="rotation"
      :zoom.sync="view.zoom"
    />

    <map-export-dialog
      ref="exportDialog"
      :data="map"
      :features="selected"
    />

    <map-issue ref="mapIssue"/>

    <measurer
      v-if="state.measureType"
      ref="measurer"
      :measure-type="state.measureType"
      @findObjects="findObjects"
    />

    <vl-layer-vector
      id="draw-pane"
      :z-index="6"
    >
      <vl-source-vector ident="draw-target"/>
    </vl-layer-vector>
    <vl-interaction-modify source="draw-target"/>
    <vl-interaction-snap source="draw-target"/>

    <vl-layer-group
      v-if="showLayerGroup"
      ref="layerGroup"
      :extent="transform(view.extent)"
    >
      <component
        :is="getLayerComponent(layer)"
        v-for="(layer, index) in visibleLayers"
        :key="layer.id"
        :ref="'layer-'+layer.id"
        :extent="view.extent"
        :kadNum="pkkId"
        :layer="layer"
        :resolution="resolution"
        :zIndex="index"
      />
    </vl-layer-group>

    <selected-feature
      v-if="selected.length"
      ref="selectedFeature"
      :editMode="state.editMode"
      :selected.sync="selected"
      :state="state"
      :widget="widget"
      @refresh="refreshLayer"
      @showFeatures="showSelectedFeaturesDialog"
    />

    <selected-pkk-feature
      v-if="pkkId"
      :pkk-id="pkkId"
      @close="pkkId = null"
    />

    <feature-selector-dialog
      ref="featureSelectorDialog"
      @edit="openMassEditDialog"
      @openPrintTemplateSelectorDialog="openPrintTemplateSelectorDialog"
      @update="refreshMapAndDisableMeasure"
    />

    <mass-edit-dialog
      ref="massEditDlg"
      @update="refreshMapAndDisableMeasure"
    />

    <print-template-selector-dialog
      ref="printTemplateSelectorDialog"
      :print-object-fields="false"
      @openPrintTemplateDialog="openPrintTemplateDialog"
    />

    <print-template-dialog
      ref="printTemplateDialog"
    />

    <feature-creation-dialog
      ref="featureCreationDialog"
      :layers="visibleLayers"
      :state="state"
      @refreshMap="refresh"
    />

    <portal to="map-legend">
      <map-legend :layers="visibleLayers"/>
    </portal>

    <portal to="map-simulation-bar">
      <map-simulation-bar
        :map="map"
        :selectedFeatures="selected"
      />
    </portal>

    <portal-target name="simulation-layer"/>

  </vl-map>
</template>

<script>
import ZwsLayer from '@/components/map/layer/ZwsLayer.vue'
import WmsLayer from '@/components/map/layer/WmsLayer.vue'
import ObjectLayer from '@/components/map/layer/ObjectLayer.vue'
import WmtsLayer from '@/components/map/layer/WmtsLayer.vue'
import VectorTileLayer from '@/components/map/layer/VectorTileLayer.vue'
import PkkLayer from '@/components/map/layer/PkkLayer.vue'
import MapContextMenu from '@/components/map/helpers/MapContextMenu'
import { toLonLat, transform, transformExtent } from 'ol/proj'
import FeatureCreationDialog from '@/components/map/FeatureCreationDialog'
import { EventBus } from '@/event-bus'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import zwsCommandBuilder from '@/services/zws-command-builder'
import MapSearch from '@/components/map/map-search/MapSearch'
import FeatureSelector from '@/components/map/helpers/FeatureSelector'
import SelectedFeature from '@/components/map/SelectedFeature'
import DMapControls from '@/components/map/DMapControls'
import MapExportDialog from '@/components/map/helpers/MapExportDialog.vue'
import Measurer from '@/components/map/helpers/measurer/Measurer'
import MapIssue from '@/components/map/MapIssue'
import { findPointOnSurface } from 'vuelayers/lib/ol-ext'
import XyzLayer from '@/components/map/layer/XyzLayer.vue'
import ImportFeaturesDialog from '@/components/map/ImportFeaturesDialog'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'
import { extend } from 'ol/extent'
import FeatureSelectorDialog from '@/components/map/helpers/FeatureSelectorDialog.vue'
import MassEditDialog from '@/components/layer-poi/MassEditDialog.vue'
import PrintTemplateSelectorDialog from '@/components/admin/print-template/PrintTemplateSelectorDialog.vue'
import PrintTemplateDialog from '@/components/admin/print-template/PrintTemplateDialog.vue'
import booleanIntersects from '@turf/boolean-intersects'
import messages from '@/componet-locale/map-view/messages'
import MapSearchSlideBar from '@/components/map/map-search/MapSearchSlideBar.vue'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import MapLegend from '@/components/map/MapLegend.vue'
import SelectedPkkFeature from '@/components/map/SelectedPkkFeature.vue'
import geoserverCommandBuilder from '@/services/geoserver-command-builder'
import MapSimulationBar from '@/components/map/zws/simulations/MapSimulationBar.vue'
import _ from 'lodash'
import selectedFeature from '@/components/map/SelectedFeature.vue'

export default {
  name: 'd-map',
  i18n: { messages },
  mixins: [displayServiceMixin],
  components: {
    PkkLayer,
    MapLegend,
    MapSearchSlideBar,
    PrintTemplateDialog,
    PrintTemplateSelectorDialog,
    MassEditDialog,
    FeatureSelectorDialog,
    ImportFeaturesDialog,
    XyzLayer,
    MapIssue,
    Measurer,
    MapExportDialog,
    DMapControls,
    SelectedFeature,
    FeatureSelector,
    MapSearch,
    FeatureCreationDialog,
    MapContextMenu,
    ObjectLayer,
    ZwsLayer,
    WmsLayer,
    WmtsLayer,
    VectorTileLayer,
    SelectedPkkFeature,
    MapSimulationBar
  },
  props: {
    map: Object,
    poi: Object,
    widget: {
      type: Boolean,
      default: false
    },
    sourceLayers: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    projection: 'EPSG:4326',
    showLayerGroup: true,
    resolution: undefined,
    state: {
      editMode: false,
      userLocation: null,
      measureType: null
    },
    view: {
      zoom: 1,
      center: [0, 0],
      renderer: 'canvas',
      extent: undefined
    },
    rotation: 0,
    layers: [],
    selected: [],
    geoSearch: [],
    mapIssueFeatureList: [],
    showUserLocation: false,
    selectedGeometry: null,
    pkkId: null
  }),
  mounted () {
    EventBus.$on('frameResize', () => this.reload())
    EventBus.$on('refreshMap', () => this.refresh())
    EventBus.$on('showFeature', (layer, id) => this.showFeatureById(layer, id))
    EventBus.$on('goToPoi', (poi) => this.goToPoi(poi))
    EventBus.$on('goToLayer', (layer) => this.goToLayer(layer))
    EventBus.$on('openLayerPoi', lpId => this.checkPoi(lpId))
    EventBus.$on('openWmsFeature', feature => this.openWmsFeature(feature))
    EventBus.$on('clearSelectedFeatures', () => this.selected = [])
    EventBus.$on('selectFeatures', features => this.selected = features)
    this.init()
  },
  beforeDestroy () {
    this.$refs.mapSearch.clear()
  },
  methods: {
    pointOnSurface: findPointOnSurface,
    refreshLayer (layerId) {
      this.layers.forEach(layer => {
        if (layer.id === layerId) {
          layer.visible = false
          this.$nextTick(() => layer.visible = true)
        }
      })
    },
    transform: (extent) => {
      if (!extent) return undefined
      return transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
    },
    makeExport () {
      this.$refs.exportDialog.open(this.view)
    },
    initLayers () {
      this.layers.forEach(it => {
        if (it.type === 'LAYER_POI') {
          it.zoomLevel = 10
        } else {
          it.zoomLevel = 0
        }
      })
      if (this.map) {
        if (this.map.layer) {
          this.layers = [this.map.layer]
        } else {
          let cachedLayers = this.$store.getters.getMapLayersConfig(this.map.id)
          cachedLayers = cachedLayers ? cachedLayers.layers : null

          let layerConfigList = this.map.parentMap
            ? this.map.parentMap.layerConfigList
            : this.map.layerConfigList

          if (cachedLayers && !this.widget) {
            layerConfigList.forEach(it => {
              const layerFromCache = cachedLayers.find(el => el.id === it.layer.id)
              if (!layerFromCache) return
              it.visible = layerFromCache.visible
              it.indexNumber = layerFromCache.indexNumber
            })
          }
          this.layers = layerConfigList
            .sort((a, b) => {
              return a.indexNumber > b.indexNumber ? 1 : -1
            })
            .map(it => {
              let layer = it.layer
              layer.visible = it.visible
              layer.indexNumber = it.indexNumber
              return layer
            })
        }
      }
    },
    refreshMapAndDisableMeasure () {
      this.refresh()
      this.state.measureType = null
    },
    findObjects (geometry) {
      this.selectedGeometry = geometry
      this.intersect()
    },
    async intersect () {

      const intersectionRequests = []
      let lpIntersectionDto = null
      let geoserverIntersections = []
      const zwsFeaturesGroupedByLayers = []

      let features = this.selectedGeometry
      if (features && features.length > 0) {
        let extent = features[0].getGeometry().getExtent().slice(0)
        features.forEach(function (feature) {
          extend(extent, feature.getGeometry().getExtent())
        })
        let filteredFeatures = []
        this.objectLayers.forEach(layer => {
          let component = this.$refs['layer-' + layer.id][0]
          let selectedFeatures = component.getFeaturesInExtent(extent)
          filteredFeatures.push(...selectedFeatures
            .filter((feature) => {
              let f1 = new GeoJSON().writeFeatureObject(features[0])
              let f2 = new GeoJSON().writeFeatureObject(feature)
              return booleanIntersects(f1, f2)
            }))
        })

        this.$refs.measurer.startLoading()

        intersectionRequests.push(
          this.$axios
            .post('layer-poi/search-intersection-dto', filteredFeatures.map(el => el.id_))
            .then(res => lpIntersectionDto = res.data)
        )
      }

      let bbox = features[0].getGeometry().getExtent().slice(0)
      bbox = transformExtent(bbox, 'EPSG:3857', 'EPSG:4326')
      intersectionRequests.push(
        geoserverCommandBuilder.getFeaturesByBbox(bbox, this.wmsLayers)
          .then(res => geoserverIntersections = res)
      )

      this.zwsLayers.forEach(l => {
        const coordinatesString = features[0].getGeometry().getCoordinates()[0].map(it => it.reverse().join(',')).join(' ')
        intersectionRequests.push(
          zwsCommandBuilder.getElemsByPolygonCoordinates(l, coordinatesString)
            .then(features => zwsFeaturesGroupedByLayers.push({ ...l, features }))
        )
      })

      await Promise.all(intersectionRequests)

      this.$refs.featureSelectorDialog.open(lpIntersectionDto, geoserverIntersections, zwsFeaturesGroupedByLayers)
      this.$refs.measurer.finishLoading()
    },
    createMapIssue (coordinates) {
      if (this.map) {
        this.$refs.mapIssue.createMapIssue(coordinates, this.map.id)
      }
    },
    goToPoi (poi) {
      if (poi.layer.type === 'WMS') {
        this.openWmsFeature(poi)
      } else {
        let layer = this.zwsLayers.find(layer => layer.id === poi.layer.id)
        if (!layer) return
        this.showFeatureById(layer, poi.sys)
      }
    },
    async showFeatureById (layer, id) {
      if (this.layers.every(l => l.id !== layer.id)) {
        return EventBus.$emit('showInfoMessage', this.$t('layerNotFound'))
      }
      this.selected = []
      let feature = await ZwsCommandBuilder.getElemsByID(layer, [id])
        .then(features => features[0])
      this.$emit('closeSearchLpDialog')
      this.$store.dispatch('setReturnToSearchLpDialog', true)
      this.showFeature(feature, true)
    },
    async showFeature (feature, viewPoint = false, multiselect = false) {
      if (feature.dComponent === 'layer-poi-feature-card') {
        const lp = await this.$axios.get(this.isAuthenticated ? 'layer-poi/find-feature-card-dto' : 'public-data/get-lp-by-id', {
          params: { id: feature.id }
        })
          .then(res => ({ ...res.data, dComponent: feature.dComponent }))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        setTimeout(
          () => {
            if (multiselect) {
              if (this.selected.some(f => f.id === lp.id)) {
                this.selected = this.selected.filter(f => f.id !== lp.id)
              } else {
                this.selected.push(lp)
              }
            } else {
              this.selected = [lp]
            }
          },
          200
        )
      } else if (feature.dComponent === 'geo-server-feature-card') {
        this.selected = [feature]
        setTimeout(
          () => this.$refs.selectedFeature.show(feature),
          200
        )
      } else if (feature.type === 'PKK') {
        return this.showPkkFeature(feature)
      } else {
        const f = feature.isShortInfoFeature
          ? await zwsCommandBuilder.getElemsByID(feature.layer, [feature.elemId]).then(features => features[0])
          : feature
        if (multiselect) {
          this.selected.push(f)
        } else {
          this.selected = [f]
          if (viewPoint) {
            this.view.center = this.pointOnSurface(f.geometry)
          }
        }
      }
    },
    showPkkFeature (feature) {
      this.pkkId = feature.attrs.id
    },
    getCoordinates (feature) {
      switch (feature.type) {
        case 'Polygon':
          return [feature.coordinates]
        case 'LineString':
          return feature.coordinates
        case 'Point':
          return feature.coordinates[0]
      }
    },
    findUserLocation () {
      this.showUserLocation = true
    },
    showBbox (bbox) {
      let extent = transformExtent(bbox, 'EPSG:4326', 'EPSG:3857')
      this.$refs.view.fit(extent)
      this.$emit('closeSearchLpDialog')
    },
    showPoi (item, byCoordinates = false) {
      this.selected = []
      if (byCoordinates) {
        this.view.center = item
        return
      }
      setTimeout(
        () => this.selected = [item],
        200
      )
      if (item.geometry) {
        this.view.center = this.pointOnSurface(item.geometry)
      }
      this.$emit('closeSearchLpDialog')
      this.$store.dispatch('setReturnToSearchLpDialog', true)
    },
    async getZwsFeaturesByCoordinates (layer, coordinate) {
      let scale = 2 * 3.1415926535 * 6378137.0 / (256 * (1 << this.view.zoom))
      return await ZwsCommandBuilder.selectElemsByXY(layer, coordinate, scale)
    },
    async click (value) {
      if (this.state.measureType || this.state.editMode) return

      let featureList = []
      if (!value.originalEvent.altKey) this.selected = []
      this.pkkId = null

      let coordinate = transform(value.coordinate, 'EPSG:4326', 'EPSG:3857')

      for (const layer of this.wmsLayers) {
        let layerObject = this.$refs['layer-' + layer.id][0]
        layer.layerObject = layerObject
        const features = await layerObject.getFeatures(coordinate)
        featureList.push(...features)
      }

      if (this.pkkLayers.length > 0) {
        const searchCoordText = toLonLat(coordinate).reverse()
        await this.$axios.get(`https://apipkk.ru/api/features/1?text=${searchCoordText}&limit=10&skip=0&tolerance=4`, {
          withCredentials: false
        })
          .then(res => res.data.features)
          .then(features => {
            this.showPkkFeature(featureList.push(
              ...features.map(it => ({ ...it, type: 'PKK' }))
            ))
          })
          .catch(er => console.info(er))
      }

      let lpResult = this.$refs.map.getFeaturesAtPixel(value.pixel)
      if (lpResult) lpResult = lpResult.filter(el => typeof el.id_ === 'number')
      if (lpResult) {
        await this.$axios
          .post(this.isAuthenticated ? 'layer-poi/search-by-ids' : 'public-data/search-lps-by-id', lpResult.map(it => it.id_))
          .then(response => {
            const lps = response.data.lps
            lps.forEach(lp => lp.dComponent = 'layer-poi-feature-card')
            featureList.push(...lps)
          })
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      }

      if (this.zwsLayers.length) {
        for (const layer of this.zwsLayers) {
          const features = await this.getZwsFeaturesByCoordinates(layer, value.coordinate)
          features.forEach(el => el.dComponent = 'zws-feature-card')
          if (features.length) featureList.push(...features)
        }
      }

      if (value.originalEvent.altKey) {

        if (this.selected.some(el => el.dComponent !== 'layer-poi-feature-card' && el.dComponent !== 'zws-feature-card')) {
          return EventBus.$emit('showInfoMessage', this.$t('unavailableForLayer'))
        }

        const featuresToSelect = featureList.filter(el => el.dComponent === 'layer-poi-feature-card' || el.dComponent === 'zws-feature-card')

        if (featuresToSelect.length > 1) {
          this.$refs.featureSelector.open(featuresToSelect, {
            x: value.originalEvent.x|0,
            y: value.originalEvent.y|0
          }, true)
        } else if (featuresToSelect.length === 1) {
          this.showFeature(featuresToSelect[0], false, true)
        }
        return
      }

      if (featureList.length === 1) {
        this.showFeature(featureList[0])
      } else if (featureList.length > 1) {
        this.$refs.featureSelector.open(
          featureList,
          { x: value.originalEvent.x|0, y: value.originalEvent.y|0 }
        )
      }
    },
    init () {
      this.initLayers()
      this.searchCenter()
      this.loadLayerDetails()
    },
    parseInitialPosition (position) {
      if (!position) return null
      let value = {
        extent: [-528.00000000000000, -90.00000000000000, 551.00000000000000, 90.00000000000000],
        center: [0, 0],
        zoom: 6
      }
      if (position.extent && typeof position.extent === 'string' && position.extent.length > 1) {
        let coordinates = position.extent.split(',')
        if (coordinates.length === 4) {
          value.extent = coordinates.map(it => parseFloat(it))
        }
      }

      if (Array.isArray(position.center) && position.center.length === 2) {
        value.center = position.center
      } else if (position.center && typeof position.center === 'string' && position.center.length > 1) {
        let coordinates = position.center.split(',')
        if (coordinates.length === 2) {
          value.center = coordinates.map(it => parseFloat(it))
        }
      }

      if (position.zoom) {
        value.zoom = parseInt(position.zoom)
      }
      return value
    },
    async searchCenter () {
      let position
      if (this.map) {
        const cachedView = this.$store.getters.getMapView(this.map.id)
        if (!this.widget && cachedView) {
          position = cachedView.view
        } else {
          position = this.parseInitialPosition(this.map.position)
        }
      }

      if (this.map && position && (position.center || position.extent)) {
        this.map.position.zoom = parseInt(this.map && this.map.position && this.map.position.zoom) || undefined
        this.view = position
      } else if (this.zwsLayers.length) {
        let bbox = await ZwsCommandBuilder.getBboxForLayerList(this.zwsLayers)
        if (this.$refs.view && bbox) {
          let extent = this.$refs.view.extentToViewProj(Object.values(bbox))
          await this.$refs.view.fit(extent, { duration: 1000 })
        }
      } else if (this.map && this.map.layer && this.layers.length === 1 && this.layers[0].type === 'LAYER_POI') {
        let attempt = 0
        while (attempt < 15) {
          try {
            let layer = this.$refs[`layer-${this.layers[0].id}`][0]
            const extent = layer.getExtent()
            await this.$refs.view.fit(extent, { duration: 300 })
            break
          } catch (e) {
            await new Promise(res => setTimeout(res, 300))
            attempt = attempt + 1
          }
        }
      }

      if (this.poi) {
        setTimeout(() => {
          this.goToPoi(this.poi)
        }, this.poi.layer.type === 'WMS' ? 300 : 1500)
      }
    },
    getPosition () {
      return {
        center: this.view.center.toString(),
        zoom: this.view.zoom,
        extent: this.calculateExtent().toString()
      }
    },
    calculateExtent () {
      const olView = this.$refs.view.$view
      if (olView == null) return
      return transformExtent(olView.calculateExtent(), 'EPSG:3857', 'EPSG:4326')
    },
    reload () {
      if (!this.$refs.map) return
      setTimeout(() => {
        if (this.$refs.map) {
          this.$refs.map.updateSize()
        }
      }, 200)
    },
    openLayerPoi (id) {
      this.$axios
        .get(this.isAuthenticated ? 'layer-poi/find-by-id' : 'public-data/get-lp-by-id', { params: { id: id } })
        .then(response => {
          let poi = response.data
          if (poi.geometry.type && poi.geometry.type === 'Point') {
            this.view.center = poi.geometry.coordinates
            this.view.zoom = 19
            return
          }
          poi.type = 'Feature'
          let source = new Vector({
            features: (
              new GeoJSON({
                featureProjection: 'EPSG:3857'
              })).readFeatures(poi)
          })
          setTimeout(() => {
            this.$refs.view.fit(source.getExtent(), { duration: 500 })
            this.showFeature({ ...poi, dComponent: 'layer-poi-feature-card' })
          }, 500)
        })
    },
    mapCreated (vm) {
      if (this.$route.query.layerPoiId) {
        this.openLayerPoi(this.$route.query.layerPoiId)
      }
      vm.$map.getViewport()
        .addEventListener('contextmenu', evt => {
          evt.preventDefault()
          let coordinate = transform(vm.$map.getEventCoordinate(evt), 'EPSG:3857', 'EPSG:4326')
          this.$refs.contextMenu.open(evt, coordinate)
        })
      setTimeout(() => {
        const view = vm.$map.getView()
        const extent = view.calculateExtent()
        const size = vm.$map.getSize()
        this.resolution = view.getResolutionForExtent(extent, size)
      }, 100)
    },
    createFeature () {
      this.$refs.featureCreationDialog.open()
    },
    getLayerComponent (layer) {
      switch (layer.type) {
        case 'ZWS':
          return 'zws-layer'
        case 'WMS':
          return 'wms-layer'
        case 'LAYER_POI' :
          return 'object-layer'
        case 'XYZ' :
          return 'xyz-layer'
        case 'WMTS':
          return 'wmts-layer'
        case 'VECTOR_TILE':
          return 'vector-tile-layer'
        case 'PKK':
          return 'PkkLayer'
      }
    },
    runImport () {
      if (this.map) {
        this.$refs.importFeaturesDialog.open(this.objectLayers)
      }
    },
    async refresh () {
      this.showLayerGroup = false
      setTimeout(() => {
        this.showLayerGroup = true
      }, 200)
    },
    async loadLayerDetails () {
      this.showLayerGroup = false
      for (let layer of this.allZwsLayers) {
        let theme = ZwsCommandBuilder.getLayerThemes(layer)
        let labels = ZwsCommandBuilder.getLayerLabels(layer)
        Promise
          .all([theme, labels])
          .then(() => {
            if (this.map.layerConfigList) {
              this.setThemes(layer)
              this.setLabels(layer)
            }
          })
        ZwsCommandBuilder.getLayerProps(layer)
        ZwsCommandBuilder.getLayerTypes(layer)
      }

      this.showLayerGroup = true
      this.reload()
    },
    showSelectedFeaturesDialog () {
      const zwsFeaturesGroupedByLayerId = _.groupBy(this.selected.filter(el => el.dComponent === 'zws-feature-card'), el => el.layer.id)
      const zwsFeaturesGroupedByLayer = Object.keys(zwsFeaturesGroupedByLayerId)
        .map(key => ({
          ...this.map.layerConfigList.map(l => l.layer).find(l => l.id === +key),
          features: zwsFeaturesGroupedByLayerId[key]
        }))

      const lpFeatureIds = this.selected
        .filter(el => el.dComponent === 'layer-poi-feature-card')
        .map(el => el.id)

      if (lpFeatureIds.length) {
        this.$axios
          .post('layer-poi/search-intersection-dto', lpFeatureIds)
          .then(res => this.$refs.featureSelectorDialog.open(res.data, null, zwsFeaturesGroupedByLayer))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      } else {
        this.$refs.featureSelectorDialog.open(null, null, zwsFeaturesGroupedByLayer)
      }
    },
    showFeaturesDialog (features) {
      const zwsFeaturesGroupedByLayerIds = _.groupBy(features, f => f.layer.id)
      const zwsFeaturesGroupedByLayer = Object.keys(zwsFeaturesGroupedByLayerIds)
        .map(key => ({
          ...this.map.layerConfigList
            .map(it => it.layer)
            .find(l => l.id === +key),
          features: zwsFeaturesGroupedByLayerIds[key]
        }))
      this.$refs.featureSelectorDialog.open(null, null, zwsFeaturesGroupedByLayer)
    },
    setThemes (layer) {
      let config = this.map.layerConfigList.find(it => it.layer.id === layer.id)

      if (config.themes && layer.themes) {
        let themes = config.themes.split(',')
        for (let themeId of themes) {
          let theme = layer.themes.find(it => it.id === themeId)
          theme.isActive = true
        }
      }
    },
    async setLabels (layer) {
      let config = this.map.layerConfigList.find(it => it.layer.id === layer.id)

      if (config.labels && layer.labels) {
        let labels = config.labels.split(',')
        for (let labelId of labels) {
          let label = layer.labels.find(it => it.id === labelId)
          label ? label.isActive = true : null
        }
      }
    },
    restoreRotation () {
      this.$refs.view.animate({
        rotation: 0,
        duration: 600
      })
    },
    openMassEditDialog (features) {
      this.$refs.massEditDlg.open(features)
    },
    openPrintTemplateSelectorDialog (templates) {
      this.$refs.printTemplateSelectorDialog.open(templates)
    },
    async openPrintTemplateDialog (id) {
      const selectedGeoserverFeatures = this.$refs.featureSelectorDialog.getSelectedGeoserverFeatures()
      const lpsDto = this.$refs.featureSelectorDialog.getSelectedLps()
      const lps = await this.$axios.post('layer-poi/get-print-template-dto', lpsDto.map(el => el.id))
        .then(res => res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      this.$refs.printTemplateDialog.open(id, { pois: [...lps, ...selectedGeoserverFeatures] })
    },
    checkPoi (lpId) {
      this.$axios.get('map/find-by-lp', {
        params: {
          layerPoiId: lpId
        }
      })
        .then(({ data }) => {
          if (data.filter(el => el.id === this.map.id).length > 0) {
            this.goToLayerPoiById(lpId)
          } else {
            EventBus.$emit('showInfoMessage', this.$t('objectNotFound'))
          }
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    async openWmsFeature (feature) {
      const layerObjects = this.$refs['layer-' + feature.layer.id]
      if (!layerObjects || !layerObjects.length) return EventBus.$emit('showInfoMessage', this.$t('layerNotFound'))
      let layerObject = layerObjects[0]
      const coordinates = transform(feature.geometry.coordinates.flat(Infinity).slice(0, 2), 'EPSG:4326', 'EPSG:3857')
      let features = await layerObject.getFeatures(coordinates)
      if (!features || !features.length) {
        EventBus.$emit('showInfoMessage', this.$t('objectNotFound'))
        return
      }
      const f = features[0]
      f.dComponent = 'geo-server-feature-card'
      this.showFeature(f)
      this.$refs.view.fit(feature.geometry, { duration: 500 })
    },
    goToLayerPoiById (id) {
      this.$axios.get('layer-poi/find-by-id', { params: { id } })
        .then(({ data }) => {
          data.dComponent = 'layer-poi-feature-card'
          this.showPoi(data)
        })
        .catch(() => {
          EventBus.$emit('showInfoMessage', this.$t('objectNotFound'))
        })
    }
  },
  computed: {
    selectedFeature () {
      return selectedFeature
    },
    visibleLayers () {
      return this.layers.filter(it => it.visible)
    },
    allZwsLayers () {
      return this.layers.filter(layer => layer.type === 'ZWS')
    },
    zwsLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'ZWS')
    },
    wmsLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'WMS')
    },
    objectLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'LAYER_POI')
    },
    pkkLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'PKK')
    },
    showNorthButton () {
      return !(this.rotation === 0)
    }
  },
  watch: {
    map () {
      this.init()
    },
    sourceLayers: {
      deep: false,
      handler (newValue) {
        if (newValue) {
          this.layers = [...newValue]
          this.refresh()
        }
      }
    },
    view: {
      deep: true,
      handler (view) {
        if (!this.map || this.widget || !this.map.id) return
        this.$store.dispatch('updateMapView', {
          mapId: this.map.id,
          view: view,
          date: Date.now()
        })
      }
    },
    layers: {
      deep: true,
      handler (layers) {
        if (!this.map || this.widget || !this.map.id) return
        this.$store.dispatch('updateMapLayersConfig', {
          mapId: this.map.id,
          layers: layers.map(l => ({
            id: l.id,
            visible: l.visible,
            indexNumber: l.indexNumber
          })),
          date: Date.now()
        })
      }
    }
  }
}
</script>

<style>
.d-map {
  height: 100% !important;
  width: 100% !important;
}
</style>
