export function getSortingConfigFromHeaders (headers) {
  if (headers) {
    return headers.map(h => ({
      header: h,
      index: 0,
      direction: 'NULL'
    }))
  }
  return []
}

export function getFilterFromHeaders (headers) {
  if (headers) {
    return headers
      .filter(it => !it.isComment && !it.excludeFromTable)
      .map(h => ({
        header: h,
        fieldValue: '',
        value: [],
        predicate: h.defaultPredicate,
        selectAll: true,
        includeEmpty: true
      }))
  }
}

export function applyFilterFromPreset (filter, preset) {
  filter.forEach(it => {
    const presetItem = preset.find(el => el.header.id === it.header.id)
    if (presetItem) {
      it.fieldValue = presetItem.fieldValue
      it.value = presetItem.value
      it.predicate = presetItem.predicate
      it.selectAll = presetItem.selectAll
      it.includeEmpty = presetItem.includeEmpty
    }
  })
}

export function applySortingConfigFromPreset (sortingConfig, preset) {
  sortingConfig.forEach(it => {
    const presetItem = preset.find(el => el.header.id === it.header.id)
    if (presetItem) {
      it.direction = presetItem.direction
      it.index = presetItem.sortingIndex
    }
  })
}

export function getDirection (direction) {
  switch (direction) {
    case 'ASC':
      return 'DESC'
    case 'DESC':
      return 'NULL'
    default:
      return 'ASC'
  }
}

export function getDirectionIcon (direction) {
  switch (direction) {
    case 'ASC':
      return 'mdi-sort-ascending'
    case 'DESC':
      return 'mdi-sort-descending'
    default:
      return ''
  }
}

export const etConst = {
  PAGINATION_ITEMS_PER_PAGE: [10, 20, 50, 100],
  DEFAULT_PAGINATION: {
    page: 1,
    itemsPerPage: 20,
    itemsCount: 20
  }
}
