<template>
  <v-select
    v-model="filterItem.value[0]"
    :items="selectItems"
    class="mt-1"
    dense
    hide-details
    outlined
  />
</template>

<script>
import messages from '@/componet-locale/et-filter/messages'

export default {
  name: 'BooleanHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filterItem: Object,
    filter: Array
  },
  computed: {
    selectItems () {
      return [
        { text: this.$t('withoutFilter'), value: '' },
        { text: this.$t('true'), value: true },
        { text: this.$t('false'), value: false }
      ]
    }
  }
}
</script>
