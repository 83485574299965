<template>
  <v-row justify="center" style="min-height: 32px">
    <v-img
      :max-height="maxHeight"
      :max-width="maxWidth"
      :src="image"
      class="mx-auto"
      contain
    >
      <template v-slot:placeholder>
        <v-row
          align="center"
          class="fill-height ma-0"
          justify="center"
        >
          <v-progress-circular
            color="grey lighten-5"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-row>
</template>

<script>

export default {
  name: 'ImgFromZulu',
  props: { image: String },
  computed: {
    maxWidth () {
      return screen.width * 0.7
    },
    maxHeight () {
      return screen.height * 0.7
    }
  }
}
</script>
