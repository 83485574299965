<template>
  <v-dialog v-if="dialog" v-model="dialog" fullscreen style="z-index: 10001">
    <v-card color="rgba(0,0,0,0.85)" dark tile>
      <v-card-title>
        <v-btn class="mr-2" icon @click="dialog = false">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn v-if="image" class="mr-2" icon @click="download">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-0">
        <div :class="$vuetify.breakpoint.smAndUp ? 'v-overlay': ''">
          <img-from-zulu :image="image"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ImgFromZulu from '@/components/map/helpers/ImgFromZulu'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'

export default {
  name: 'PhotoDialog',
  components: { ImgFromZulu },
  props: ['feature'],
  data: () => ({
    dialog: false,
    field: null,
    image: ''
  }),
  created () {
    this.getBlob()
  },
  methods: {
    async open (field, feature) {
      this.dialog = true
      this.field = field
      this.feature = feature
      this.getBlob()
    },
    async getBlob () {
      this.image = await ZwsCommandBuilder.getBlob(this.field.url)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    async download () {
      const a = document.createElement('a')
      a.href = this.image
      const date = this.formatDateDIN(new Date(Date.now()))
      a.download = `${this.field.username} [${date}].png`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
